<template>
  <div>
    <div class="inline">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thêm loại khách hàng'">
          <template v-slot:preview>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <div class="row">
                <b-form-group class="col-4">
                  <template>
                    <label>Tên:</label>
                  </template>
                  <b-form-input
                    size="sm"
                    type="text"
                    v-model="customerType.name"
                    :state="validateState('name')"
                    required
                    placeholder="Nhập tên loại khách hàng"
                    class="mb-3"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    Vui lòng nhập tên loại khách hàng
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group class="col-4">
                  <template>
                    <label>Số tiền cần tiêu dùng:</label>
                    <span class="text-danger">*</span>
                  </template>

                  <b-input-group>

                    <b-form-input
                      class="input-style text-right"
                      size="sm"
                      type="text"
                      v-model="customerType.upgradeCost"
                      :state="validateState('upgradeCost')"
                      v-mask="currency"
                      placeholder="Số tiền"
                    ></b-form-input>

                    <b-input-group-append>
                      <span
                        class="input-group-text p-2 pl-3 pr-3"
                        style="color: #ffffff; background: #2ba0cb"
                        id="inputGroup-sizing-sm"
                      >VND</span>
                    </b-input-group-append>
                    <b-form-invalid-feedback id="input-live-feedback">
                      Vui lòng nhập số tiền cần tiêu dùng
                    </b-form-invalid-feedback>
                  </b-input-group>

                </b-form-group>
                <b-form-group class='col-4'>
                  <template>
                    <label>Trạng thái:</label>
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    size="sm"
                    required
                    v-model="customerType.status"
                    :options="listStatus"
                    :state="validateState('status')"
                    
                    class="select-style"
                    value-field="id"
                    text-field="name"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback id="input-live-feedback">Vui lòng chọn trạng thái</b-form-invalid-feedback>
                </b-form-group>
              </div>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <template>
                      <label>Mô tả:</label>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-textarea
                      size="sm"
                      v-model="customerType.description"
                      :placeholder="'Thêm mô tả'"
                      :rows="6"
                      :max-rows="8"
                      :state="validateState('description')"
                    ></b-form-textarea>
                    <b-form-invalid-feedback id="input-live-feedback">Vui lòng nhập mô tả</b-form-invalid-feedback>

                  </b-form-group>
                </b-col>
              </b-row>
            </v-form>
          </template>
          <template v-slot:foot>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="createCustomer"
            >Lưu</b-button>
            <router-link :to="'/customer-type'">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
              >Hủy</b-button>
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
.gender-appellation {
  display: flex;
  gap: 4px;
}

.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
// import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import {
  CUSTOMER_TYPE,
  CUSTOMER_GENDER,
  CUSTOMER_APPELLATION,
} from '@/utils/constants';
// Import datePicker component
// import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
// import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { xoa_dau } from '../../../utils/common';
import { currencyMask, unMaskPrice } from '../../../utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      currency: currencyMask,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      customerType: {
        name: null,
        upgradeCost: null,
        description: null,
        status: null,
      },
      valid: true,
      form: {
        name: '',
        phone: '',
        selectedGender: null,
        appellation: null,
      },
      listStatus: [
        { id: null, name: 'Chọn trạng thái' },
        { id: false, name: 'Tắt' },
        { id: true, name: 'Bật' },
      ],
      isNew: true,
      radios: 'male',
      selectedType: null,
      listType: CUSTOMER_TYPE,
      note: '',
      birthday: '',
      address: '',
      selectedCity: null,
      listCity: [],
      selectedDistrict: null,
      listDistrict: [],
      selectWard: null,
      listWard: [],
      listGender: CUSTOMER_GENDER,
      listAppellation: CUSTOMER_APPELLATION,
      selectedEmployee: null,
      listEmployee: [],
      lastName: '',
      filteredOptionEmployee: [],
      optionEmployee: [],
      searchEmployee: '',
      email: '',
      showErrorGender: false,
    };
  },
  validations: {
    customerType: {
      status: { required },
      upgradeCost: { required },
      name: { required },
      description: { required },
    },
  },
  components: {
    KTCodePreview,
    // datePicker,
    // Autosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách loại khách hàng', route: '/customer-type' },
      { title: 'Thêm loại khách hàng' },
    ]);
  },
  created() {
    this.fetchEmployee();
    this.fetchCity();
  },
  methods: {
    format(value) {
      this.birthday = value;
      return value;
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.customerType[name];
        return $dirty ? !$error : null;
      }
    },
    createCustomer: async function () {
      this.isNew = false;
      this.$v.customerType.$touch();
      if (this.$v.customerType.$anyError) {
        return;
      }
      this.customerType['upgradeCost'] = unMaskPrice(
        this.customerType['upgradeCost'] || 0,
      );
      const data = {
        name: this.customerType.name,
        description: this.customerType.description,
        upgradeCost: this.customerType.upgradeCost,
        status: this.customerType.status,
      };
      ApiService.post('customer/setting-type', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.$router.push({
              path: '/customer-type',
            });
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch((error) => {
          console.log('error: ', error);
          this.makeToastFaile('Thêm loại khách hàng bị lỗi!!!!');
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchEmployee: async function () {
      this.optionEmployee = [];
      ApiService.setHeader();
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          let nameNoneSign = xoa_dau(element.name);
          let store = {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
          this.optionEmployee.push(store);
        });
        this.filteredOptionEmployee = [...this.optionEmployee];
      });
    },
    fetchCity() {
      ApiService.setHeader();
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistrict() {
      this.selectedDistrict = null;
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedCity}/district`).then((data) => {
        this.listDistrict = data.data.data;
      });
    },
    getWard() {
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedDistrict}/ward`).then((data) => {
        this.listWard = data.data.data;
      });
    },
    onContext(ctx) {
      if (ctx.selectedYMD == '') {
        this.birthday = '';
      } else {
        this.birthday = ctx.selectedYMD;
      }
    },
    onSelectedEmployee(option) {
      this.searchEmployee = option.item.name;
      this.selectedEmployee = option.item.id;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionEmployee
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionEmployee = [...filteredData];
    },
  },
};
</script>
